<div class="content">
  <h1>Job Management</h1>

  <form class="panel" (ngSubmit)="loadJobs()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.companyId" [label]="'Companies'" [list]="companies" [value]="'companyId'" [name]="'companyName'" (onChanged)="companyChanged($event)"></app-search-select>
      </div>

      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.locationId" [label]="'Locations'" [list]="locations" [value]="'locationId'" [name]="'name'"></app-search-select>
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">ACC #</mat-label>
          <input type="text" matInput placeholder="Acc #" name="accNum" [(ngModel)]="filter.accNum" >
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">JOB #</mat-label>
          <input type="text" matInput placeholder="Job #" name="salesOrderNum" [(ngModel)]="filter.salesOrderNum" >
        </mat-form-field>
      </div>
    </div>

    <div class="panel-btns__block">
      <button class="panel-btn panel-btn-margin" type="button" (click)="clear()">
        CLEAR
      </button>

      <button class="panel-btn" type="submit">
        FILTER
      </button>

      <button class="add-btn" type="button" (click)="add()">
        ADD NEW
      </button>
    </div>
  </form>

  
  <div class="spinner-container" *ngIf="(dataSource.loading$ | async) || isLoading">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>


  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
  
    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef>COMPANY</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.companyName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>LOCATION</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.name}}</span></td>
    </ng-container>

    <ng-container matColumnDef="accNum">
      <th mat-header-cell *matHeaderCellDef>ACC #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.accNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>CREATED</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.created | date: 'dd MMM YYYY'}}</span></td>
    </ng-container>

    <ng-container matColumnDef="salesOrderNum">
      <th mat-header-cell *matHeaderCellDef>JOB #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.salesOrderNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="noOfAssetsWhenCreated">
      <th mat-header-cell *matHeaderCellDef># OF ASSETS</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.numOfAssetsWhenCreated}}</span></td>
    </ng-container>

    <ng-container matColumnDef="print">
      <th mat-header-cell *matHeaderCellDef>PRINT</th>
      <td mat-cell *matCellDef="let element"><a [routerLink]="['details', element.locationInspectionId]" class="link-icon"><mat-icon>print</mat-icon></a></td>
    </ng-container>

    <ng-container matColumnDef="complete">
      <th mat-header-cell *matHeaderCellDef>COMPLETE</th>
      <td mat-cell *matCellDef="let element"><a (click)="completeJob(element.locationInspectionId)" class="link-icon"><mat-icon>done</mat-icon></a></td>
    </ng-container>
  
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>DELETE</th>
      <td mat-cell *matCellDef="let element"><a (click)="deleteJob(element.locationInspectionId)" class="link-icon"><mat-icon>delete</mat-icon></a></td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>EDIT</th>
      <td mat-cell *matCellDef="let element"><a (click)="edit(element.locationInspectionId)" class="link-icon"><mat-icon>edit</mat-icon></a></td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>
