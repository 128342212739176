<div class="dialog-content">
  <h1>MOVE LOCATION</h1>
  <form (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill" class="full-width" *ngIf="allowCompanyMove">

      <mat-label class="form-label">Company</mat-label>
      <mat-select name="companyId" [(ngModel)]="data.companyId"(selectionChange)="onCompanyChange()">
        <mat-option *ngFor="let company of companies" [value]="company.companyId">
          {{company.companyName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label class="form-label">Location</mat-label>
      <mat-select name="locationId" [(ngModel)]="locationId">
        <mat-option *ngFor="let location of locations" [value]="location.locationId">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="action-buttons">
      <button class="btn-save" type="submit">
        MOVE
      </button>
  
      <button class="btn-cancel" type="button" (click)="cancel()">
        CANCEL
      </button>
    </div>
  </form>
</div>
