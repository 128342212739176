import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Equipment } from 'src/app/models/equipment';
import { Location } from 'src/app/models/location';
import { Company } from 'src/app/models/company';
import { EquipmentService } from 'src/app/services/administration/equipment.service';
import { LocationService } from 'src/app/services/administration/location.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from '../../services/administration/company-service';

@Component({
  selector: 'app-move-equip',
  templateUrl: './move-equip.component.html',
  styleUrls: ['./move-equip.component.scss']
})
export class MoveEquipComponent implements OnInit {

  locations: Location[];
  locationId: number = 0;
  allowCompanyMove: boolean = false;
  companies: Company[];
  bigBoyUsers: string;
  currentUser;
  constructor(private locationService: LocationService,private companyService: CompanyService, private authService: AuthService, private equipService: EquipmentService,
    @Inject(MAT_DIALOG_DATA) public data: { equipmentId: number, batchIds, companyId: number },
    public dialogRef: MatDialogRef<MoveEquipComponent>) {
    authService.currentUser.subscribe((user) => {
      this.currentUser = user;

      this.authService.getBigBoyUsers().then((users) => {
        this.allowCompanyMove = users.includes(user.username);
      });
        //this.allowCompanyMove = (user.userTypeId == 5 || user.userTypeId == 3)
    });
  }

  ngOnInit(): void {

    this.companyService.getAll(-1, false, '').then((locations) => {
      this.companies = locations;
    });

    if (this.data.companyId != null) {
      this.locationService.getAll(this.data.companyId, false, '').then((locations) => {
        this.locations = locations;
      });
    } else {
      this.equipService.get(this.data.equipmentId).then((equipment: Equipment) => {
        this.locationService.getAll(equipment.companyId, false, '').then((locations) => {
          this.locations = locations;
        });
      });
    }
  }

  onCompanyChange() {
    this.locationId = 0;
    this.locationService.getAll(this.data.companyId, false, '').then((locations) => {
      this.locations = locations;
    });
  }

  onSubmit() {
    if (this.locationId == null) {
      return null;
    }

    var user = this.authService.currentUser.value;

    if (this.data.batchIds != null && this.data.batchIds != undefined) {
      this.equipService.moveLocationBatch(this.data.batchIds, this.locationId, user.name).then(() => {
        this.dialogRef.close({
          success: true
        });
      });
    } else if (this.data.equipmentId != null && this.data.equipmentId != undefined){
      this.equipService.moveLocation(this.data.equipmentId, this.locationId, user.name).then(() => {
        this.dialogRef.close({
          success: true
        });
      });
    }
  } 

  cancel() {
    this.dialogRef.close({
      success: false
    });
  }
}
