import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { JobService } from 'src/app/services/administration/job-service';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-view-jobs-dialog',
  templateUrl: './view-jobs-dialog.component.html',
  styleUrls: ['./view-jobs-dialog.component.scss']
})
export class ViewJobsDialogComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['salesOrderNum', 'created', 'inspector', 'summary', 'jobForm', 'rams', 'view'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<ViewJobsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {companyId: number},
  private jobService: JobService, private reportableService: ReportableService) { }

  ngOnInit(): void {
    console.log(this.data.companyId);
    this.jobService.getCompletedJobs(this.data.companyId).subscribe((data) => {
      console.log(data)
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  downloadRams(locationInspectionId) {
    this.reportableService.getRAMS(locationInspectionId).then((data) => {
      importedSaveAs(data, 'RAMS Report.pdf');
    });
  }
  
  downloadJobForm(locationInspectionId) {
    this.reportableService.getJobForm(locationInspectionId).then((data) => {
      importedSaveAs(data, 'Job Form.pdf');
    });
  }   
  downloadSummary(locationInspectionId, sales_Order) {
    this.reportableService.getSummaryReport(locationInspectionId).then((data) => {
      importedSaveAs(data, sales_Order + ' Inspection Report.pdf');
    });
  }  
  downloadServiceAgreement(Id) {
    this.reportableService.getServiceAgreement(Id).then((data) => {
      importedSaveAs(data, 'Service Agreement.pdf');
    });
  }

  view(salesOrderNum) {
    this.dialogRef.close({
      salesOrderNum: salesOrderNum
    });
  }
}
