import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SnotifyService } from 'ng-snotify';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss']
})
export class ImagePopUpComponent implements OnInit {
  @ViewChild('imgRef') img:ElementRef;

  constructor(public dialogRef: MatDialogRef<ImagePopUpComponent>, private sanitizer: DomSanitizer,
    private snotifyService: SnotifyService, @Inject(MAT_DIALOG_DATA)  public data: { base64Array:  string[],dto:  any[], title: any }) { }

  currentStep: number = 0;
  maxStep: number = 0;
  base64: any = "";
  receivedDate: string = null;

  ngOnInit(): void {
    if (this.data.dto != undefined) {
 
      this.maxStep = this.data.dto.length;
      this.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.dto[0].base64}`);
      this.receivedDate = this.data.dto[0].timestamp;
    } else if(this.data.base64Array.length > 0){
      console.log(this.data);
      this.maxStep = this.data.base64Array.length;
      this.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.base64Array[0]}`);
    }else{
        this.snotifyService.error("No Images Loaded");
        this.dialogRef.close();
    }
    
  }

  nextImage() {

    if (this.data.base64Array != undefined && this.currentStep == this.data.base64Array.length - 1)
      this.currentStep = -1;

    if (this.data.dto != undefined && this.currentStep == this.data.dto.length - 1)
      this.currentStep = -1;

    this.currentStep = this.currentStep + 1;
    //this.base64 = this.data.base64Array[this.currentStep];
    if (this.data.base64Array != undefined) {
      this.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.base64Array[this.currentStep]}`);
    }
    else if (this.data.dto != undefined) {
      this.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.dto[this.currentStep]}`);
      this.receivedDate = this.data.dto[this.currentStep].timestamp;
    }

    console.log(this.currentStep);
    console.log(this.data.base64Array.length);
  }

  previousImage() {
    if (this.currentStep == 0)
      this.currentStep = this.data.base64Array.length;

    this.currentStep = this.currentStep - 1;
    //this.base64 = this.data.base64Array[this.currentStep];
    if (this.data.base64Array != undefined) {
      this.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.base64Array[this.currentStep]}`);
    } else if (this.data.dto != undefined) {
      this.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.dto[this.currentStep]}`);
      this.receivedDate = this.data.dto[this.currentStep].timestamp;
    }
    console.log(this.currentStep);
    console.log(this.data.base64Array.length);
  }

  cancel() {
    this.dialogRef.close();
  }

} 
