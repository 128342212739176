import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";

@Injectable()
export class AuthService {
  currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private router: Router) {
    var user = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser.next(user);
  }

  getBigBoyUsers(): Promise<String> {
    return new Promise((resolve, reject) => {
      this.http.get('assets/test.txt', { responseType: 'text' }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null);
      });
    });
  }
  isAuthenticated(): boolean {
    var user = localStorage.getItem("currentUser");
    if (user == null || user == "null")
      return false;

    return true;
  }

  isClient() {
    if (!this.isAuthenticated())
      return false;

    var user = JSON.parse(localStorage.getItem("currentUser"));
    return user.userTypeId != 5;
  }

  isMasterAdmin() {
    if (!this.isAuthenticated())
      return false;

    var user = JSON.parse(localStorage.getItem("currentUser"));
    return user.userTypeId == 5;
  }

  isAdmin() {
    if (!this.isAuthenticated())
      return false;

    var user = JSON.parse(localStorage.getItem("currentUser"));
    return user.userTypeId == 3;
  }

  isInspector() {
    if (!this.isAuthenticated())
      return false;

    var user = JSON.parse(localStorage.getItem("currentUser"));
    return user.userTypeId == 7;
  }

  // getLoggedInCookie(): any {
  //   var user = localStorage.getItem("@user");
  //   return user;
  // }

  logout() {
    this.currentUser.next(null);
    localStorage.setItem("currentUser", null);
    this.router.navigate(['/login']);
  }

  login(username: string, password: string, rememberMe: boolean) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      username: username,
      password: password
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "auth/login", dto, { headers: headers }).subscribe(data => {
        localStorage.setItem("currentUser", JSON.stringify(data));
        console.log(data);
        if (rememberMe)
          localStorage.setItem("@user", JSON.stringify(dto));
        
        this.currentUser.next(data);

        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  reset(username: string) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      username: username,
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "auth/reset", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  resetPassword(token: string, password: string) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      token: token,
      password: password,
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "auth/resetPassword", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
}
