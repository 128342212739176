<div class="content" *ngIf="equipment != null">
  <h1 style="padding-left: 5px;"><a [routerLink]="['/dashboard']" [queryParams]="{rfqp: true}"><i style="padding-right: 10px;" class="fas fa-arrow-left"></i>DETAILS</a></h1>

  <div class="equipment-title">
    {{ equipment.description }}
    <div class="spacer"></div>
    <button class="add-btn" type="button" (click)="downloadAssetDetails()">
      DOWNLOAD
    </button>
  </div>

  <div class="equipment-details-panel">
    <div class="details-panel">
      <div class="product-details card">
        <div class="title">Product Detail</div>

        <div class="product-details-grid">
          <div class="field"><span>Serial Num</span> <div>{{ equipment.serialNum }}</div></div>
          <div class="field"><span>Client Asset Num</span> <div>{{ equipment.assetNum }}</div></div>
          <div class="field"><span>Description</span> <div>{{ equipment.description }}</div></div>
          <div class="field"><span>WLL</span> <div>{{ equipment.wll }}</div></div>
          <div class="field"><span>Relevant Standard</span> <div>{{ equipment.australianStd }}</div></div>
          <div class="field"><span>Inspection Result</span> <div>{{ equipment.inServiceFormatted }}</div></div>
          <div class="field"><span>Asset Location</span> <div>{{ equipment.locationName }}</div></div>
          <div class="field"><span>Ranger Inspector</span> <div>{{ equipment.inspection?.inspector }}</div></div>
        </div>

        <button *ngIf="currentUser.userTypeId != 1" class="add-btn btn-edit" type="button" (click)="editEquipment()">Edit</button>
      </div>

      <div class="spacer"></div>

      <div class="inspection-details">
        <div class="status-panel card">
          <div class="title">Status</div>

          <div class="status">
            <img *ngIf="equipment.inServiceFormatted == 'Complies'" src="/assets/svgs/arrows_circle_check_colour.svg" />
            <img *ngIf="equipment.inServiceFormatted == 'Does Not Comply'" src="/assets/svgs/basic_ban_colour.svg" />
            <img *ngIf="equipment.inServiceFormatted == 'Out Of Service'" src="/assets/svgs/basic_exclamation_colour.svg" />

            <label>{{ equipment.inServiceFormatted}}</label>
          </div>
        </div>

        <div class="status-panel card">
          <div class="title">Next Inspection</div>
          <div class="next-inspection-date">
            <label class="day">{{ equipment.inspection?.nextInspectionDate | date: 'dd'}}</label>
            <label>{{ equipment.inspection?.nextInspectionDate | date: 'MMMM yyyy'}}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="details-panel">
      <div class="certificate-details card">
        <div class="title">Certificates</div>

        <div class="latest-certificate" *ngIf="inspections.length > 0">
          <app-inspection-preview [inspection]="inspections[0]"></app-inspection-preview>
          <div>
            <label *ngIf="!inspections[0].nataCert">Report of Thorough Examination</label>
            <label *ngIf="inspections[0].nataCert">Certificate of Tensile Test and Examination</label>
            <p>Status of the latest report</p>

            <button class="add-btn" type="button" (click)="downloadReport(inspections[0].inspectionId, inspections[0].safeForUse)">
              DOWNLOAD
            </button>
          </div>
        </div>

        <div class="certificates">
          <div *ngFor="let inspection of inspections">
            <app-inspection-preview [allowExport]="allowExport" [small]="'true'" [inspection]="inspection" (exportCert)="downloadReport($event)"></app-inspection-preview>
          </div>
        </div>

        <div class="cert-loader" *ngIf="generatingCert">
          <mat-progress-spinner [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
      </div>
    </div>
  </div>

  <div class="inspection-history card">
    <div class="title">Inspection History</div>

    <table mat-table [dataSource]="inspectionDataSource">

      <ng-container matColumnDef="cert">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="!element.certLoading" (click)="downloadReport(element.inspectionId, element)"><i class="fas fa-file-pdf"></i></div>
          <mat-progress-spinner *ngIf="element.certLoading" [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
        </td>
      </ng-container>

      <ng-container matColumnDef="inspectionDate">
        <th mat-header-cell *matHeaderCellDef>Inspection Date</th>
        <td mat-cell *matCellDef="let element">{{element.inspectionDate | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="inspector">
        <th mat-header-cell *matHeaderCellDef>Ranger Inspector</th>
        <td mat-cell *matCellDef="let element">{{element.inspector}}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Asset Location</th>
        <td mat-cell *matCellDef="let element">{{element.location}}</td>
      </ng-container>

      <ng-container matColumnDef="repairNotes">
        <th mat-header-cell *matHeaderCellDef>Repair Notes</th>
        <td mat-cell *matCellDef="let element">{{element.repairNotes}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Inspection Result</th>
        <td mat-cell *matCellDef="let element">{{element.inServiceFormatted}}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="inservice">
        <th mat-header-cell *matHeaderCellDef>In Service</th>
        <td mat-cell *matCellDef="let element"><div class="service-circle" [style.background-color]="element.inServiceColour"></div></td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="inspectionDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: inspectionDisplayedColumns;"></tr>
    </table>

    <mat-paginator #inspectionPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

  <div class="movement-history card">
    <div class="title">Repair History</div>

    <table mat-table [dataSource]="repairedDataSource">

      <ng-container matColumnDef="inspectionDate">
        <th mat-header-cell *matHeaderCellDef>Inspection Date</th>
        <td mat-cell *matCellDef="let element">{{element.inspectionDate | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="rangerInspector">
        <th mat-header-cell *matHeaderCellDef>Inspector</th>
        <td mat-cell *matCellDef="let element">{{element.inspector}}</td>
      </ng-container>

      <ng-container matColumnDef="batchType">
        <th mat-header-cell *matHeaderCellDef>Batch Type</th>
        <td mat-cell *matCellDef="let element">{{element.batchType}}</td>
      </ng-container>

      <ng-container matColumnDef="batchTypeNumber">
        <th mat-header-cell *matHeaderCellDef>Batch Num</th>
        <td mat-cell *matCellDef="let element">{{element.batchNum}}</td>
      </ng-container>

      <ng-container matColumnDef="loadTestTo">
        <th mat-header-cell *matHeaderCellDef>Load Test To</th>
        <td mat-cell *matCellDef="let element">{{element.loadTestTo}}</td>
      </ng-container>

      <ng-container matColumnDef="wll">
        <th mat-header-cell *matHeaderCellDef>WLL</th>
        <td mat-cell *matCellDef="let element">{{element.wll}}</td>
      </ng-container>

      <ng-container matColumnDef="repairNotes">
        <th mat-header-cell *matHeaderCellDef>Repair Notes</th>
        <td mat-cell *matCellDef="let element">{{element.repairNotes}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="repairedDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: repairedDisplayedColumns;"></tr>
    </table>

    <mat-paginator #repairedPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

  <div class="movement-history card">
    <div class="title">Movement History</div>

    <table mat-table [dataSource]="movementDataSource">

      <ng-container matColumnDef="movementDate">
        <th mat-header-cell *matHeaderCellDef>Movement Date</th>
        <td mat-cell *matCellDef="let element">{{element.movementDate}}</td>
      </ng-container>

      <ng-container matColumnDef="companyMovedTo">
        <th mat-header-cell *matHeaderCellDef>Company Moved To</th>
        <td mat-cell *matCellDef="let element">{{element.companyMovedTo}}</td>
      </ng-container>

      <ng-container matColumnDef="locationMovedTo">
        <th mat-header-cell *matHeaderCellDef>Location Moved To</th>
        <td mat-cell *matCellDef="let element">{{element.locationMovedTo}}</td>
      </ng-container>

      <ng-container matColumnDef="movedBy">
        <th mat-header-cell *matHeaderCellDef>Moved By</th>
        <td mat-cell *matCellDef="let element">{{element.movedBy}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="movementDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: movementDisplayedColumns;"></tr>
    </table>
    <mat-paginator #movementPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

  <div class="additional-files card">
    <div class="title">Supporting Documents</div>
    <div style="display:flex;flex-direction:row-reverse">
      <button class="add-btn btn-edit" *ngIf="currentUser.userTypeId == 5 || currentUser.userTypeId == 3" (click)="uploadDocument()">Upload</button>
    </div>
    <div class="files-content">
      <table class="mat-table cdk-table">
        <thead>
          <tr class="mat-header-row cdk-header-row ng-star-inserted">
            <th class="mat-header-cell cdk-header-cell ng-star-inserted">Uploader</th>
            <th class="mat-header-cell cdk-header-cell ng-star-inserted">File</th>
            <th class="mat-header-cell cdk-header-cell ng-star-inserted">Notes</th>
            <th class="mat-header-cell cdk-header-cell ng-star-inserted">Uploaded Date</th>
            <th *ngIf="currentUser.userTypeId == 5 || currentUser.userTypeId == 3" class="mat-header-cell cdk-header-cell ng-star-inserted">Remove</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let document of documents">
            <td>
              <div class="file-row">
                <span>{{ document.userName }}</span>
              </div>
            </td>
            <td>
              <div class="file-row">
                <span>{{ document.fileName }}</span>
                <i (click)="downloadDocument(document)" class="far fa-arrow-alt-circle-down"></i>
              </div>
            </td>
            <td>
              <div class="file-row">
                <span>{{ document.notes }}</span>
              </div>
            </td>
            <td>
              <label>{{ document.created_At | date: 'dd MMMM yyyy HH:mm'}}</label>
            </td>
            <td *ngIf="currentUser.userTypeId == 5 || currentUser.userTypeId == 3">
              <i (click)="archiveDocument(document)" class="far fa-trash" style="cursor:pointer;"></i>
           </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="additional-files card" *ngIf="files.length > 0">
    <div class="title">Additional Files</div>

    <div class="files-content">
      <table class="mat-table cdk-table">
        <thead>
          <tr class="mat-header-row cdk-header-row ng-star-inserted">
            <th class="mat-header-cell cdk-header-cell ng-star-inserted">File name</th>
            <!-- <th class="mat-header-cell cdk-header-cell ng-star-inserted">Download</th> -->
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let file of files">
            <td>
              <div class="file-row">
                <span>{{ file.Filename }}</span>
                <i (click)="downloadFile(file)" class="far fa-arrow-alt-circle-down"></i>
              </div>
            </td>
            <!-- <td><button>Download</button></td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="additional-files card" *ngIf="images.length > 0">
    <div class="title">Inspection Images</div>

    <div class="files-content">
      <div class="files-list">
        <div *ngFor="let image of images">
          <div>
            <!--<label *ngIf="image.filename != null">{{image.filename}}</label>-->
            <img [src]="'data:image/jpg;base64,'+image.base64" (click)="selectImage(image)" />
            <label *ngIf="image.timestamp != null">{{image.timestamp}}</label>
          </div>
        </div>
      </div>

      <div class="selected-file" *ngIf="selectedImage != null">
        <a download="image.jpg" [href]="'data:image/jpg;base64,'+selectedImage.base64">
          <img [src]="'data:image/jpg;base64,'+selectedImage.base64" />
        </a>
      </div>

      <div class="no-selected-file" *ngIf="selectedImage == null">
        <label>Image Preview</label>
      </div>
    </div>
  </div>
</div>
