import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EquipmentService } from 'src/app/services/administration/equipment.service';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentManageComponent } from '../equipment-manage/equipment-manage.component';
import { DocumentUploadComponent } from '../../../../components/supporting-document-upload/supporting-document-upload.component';
@Component({
  selector: 'app-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrls: ['./equipment-details.component.scss']
})
export class EquipmentDetailsComponent implements OnInit {
  @ViewChild("inspectionPaginator") inspectionPaginator: MatPaginator;
  @ViewChild("movementPaginator") movementPaginator: MatPaginator;
  @ViewChild("repairedPaginator") repairedPaginator: MatPaginator;

  equipment;
  inspections = []
  images = [];
  files = [];
  documents = [];
  generatingCert: boolean = false;
  selectedImage = null;

  inspectionDisplayedColumns: string[] = ['inspectionDate', 'inspector', 'location', 'status'];
  inspectionDataSource;

  movementDisplayedColumns: string[] = ['movementDate', 'companyMovedTo','locationMovedTo', 'movedBy'];
  movementDataSource;

  repairedDisplayedColumns: string[] = ['inspectionDate', 'rangerInspector', 'batchType', 'batchTypeNumber', 'loadTestTo', 'wll', 'repairNotes'];
  repairedDataSource;

  allowExport: boolean = false;
  currentUser;

  constructor(private equipmentService: EquipmentService, private activatedRoute: ActivatedRoute, private reportableService: ReportableService, 
    private authService: AuthService, public dialog: MatDialog) {
    authService.currentUser.subscribe((user) => {
      this.currentUser = user;
      console.log("User");
      console.log(user);
    })
  }

  ngOnInit(): void {
    this.allowExport = this.authService.isAdmin();

    this.activatedRoute.paramMap.subscribe(async (params) => {
      var id = params.get("id");
      this.equipment = await this.equipmentService.get(parseInt(id), true);
      this.loadData();
     
    });
  }

  loadData() {
    this.equipmentService.getInspections(parseInt(this.equipment.equipmentId)).then((inspections) => {
      this.inspections = inspections;
      this.inspectionDataSource = new MatTableDataSource<any>(inspections);
      this.inspectionDataSource.paginator = this.inspectionPaginator;
    });

    this.equipmentService.getRepairedInspections(parseInt(this.equipment.equipmentId)).then((repairedInspections) => {
      this.repairedDataSource = new MatTableDataSource<any>(repairedInspections);
      this.repairedDataSource.paginator = this.repairedPaginator;
    });

    this.equipmentService.getMovementHistory(parseInt(this.equipment.equipmentId)).then((history) => {
      this.movementDataSource = new MatTableDataSource<any>(history);
      this.movementDataSource.paginator = this.movementPaginator;
    })

    this.equipmentService.getInspectionImages(parseInt(this.equipment.equipmentId)).then((images) => {
      this.images = images;
    })

    this.equipmentService.getEquipmentFiles(parseInt(this.equipment.equipmentId)).then((files) => {
      if (files != null)
        this.files = files;
    })

    this.equipmentService.getEquipmentSupportingDocuments(parseInt(this.equipment.equipmentId)).then((files) => {
      console.log("documents");
      console.log(files);
      if (files != null)
        this.documents = files;
    })
  }

  uploadDocument() {
    const dialogRef = this.dialog.open(DocumentUploadComponent, {
      data: { equipmentId: this.equipment.equipmentId }
    });

    dialogRef.afterClosed().subscribe(async result => {
      this.loadData();
    });
  }

  archiveDocument(document) {
    if (!confirm('Are you sure you want to remove this supporting document?')) return false;
    this.equipmentService.archiveDocument(document.equipmentId, document.dbId,true).then(() => {
      this.loadData();
    });
  }

  editEquipment() {
    console.log(this.equipment);
    const dialogRef = this.dialog.open(EquipmentManageComponent, {
      width: '900px',
      data: {
        equipmentId: this.equipment.equipmentId,
        companyId: this.equipment.companyId
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.equipment = await this.equipmentService.get(parseInt(this.equipment.equipmentId), true);
      }
    });
  }

  downloadReport(inspectionId: number, safeForUse: boolean, row) {
    if (row != null)
      row.certLoading = true;
    else
      this.generatingCert = true;

    this.reportableService.getLiftingExam(inspectionId, safeForUse).then((data) => {
      importedSaveAs(data, 'Report.pdf');

      if (row != null)
        row.certLoading = false;
      else
        this.generatingCert = false;
    });
  }

  downloadFile(file) {
    var data = this.convertBase64toBlob(file.Data, file.MimeType);
    importedSaveAs(data, file.Filename);
  }

  downloadDocument(file) {
    var data = this.convertBase64toBlob(file.data, file.mimeType);
    importedSaveAs(data, file.fileName);
  }

  selectImage(image) {
    this.selectedImage = image;
  }

  downloadAssetDetails() {
    this.reportableService.getAssetExport(this.equipment.equipmentId).then((data) => {
      importedSaveAs(data, 'ProductDetails.pdf');
    });
  }

  // Need to move all these to a helper class instead of copying in each file
  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
