<div class="content">
  <div class="help-centre-container">
    <div class="block-1">
      <div class="welcome__block">
        <div class="highlight"></div>
        <h3>WELCOME TO THE</h3>
        <h2>HELP CENTRE</h2>
      </div>

      <div class="icon-text__block" [routerLink]="['/administration/change-password']">
        <i class="fas fa-unlock-alt"></i>
        <h3>RESET</h3>
        <h2>YOUR PASSWORD</h2>
      </div>
    </div>

    <div class="block-2">
      <a class="icon-text__block"  href="https://www.ranger.com.au/wp-content/uploads/2023/03/RIG-Manual-2023.pdf" target="_blank">
        <div style="display: flex; flex-direction: row; align-items: center">
          <img src="/assets/icons/User-Guide.jpg" style="width: 100px; height: 100px;" />
          <div style="margin-left: 10px;">
            <h3>USER</h3>
            <h2>MANUAL</h2>
          </div>
        </div>
      </a>

      <a class="icon-text__block" href="https://www.ranger.com.au/catalogue-2020/#p=1" target="_blank">
        <div style="display: flex; flex-direction: row; align-items: center">
          <img src="/assets/icons/Product-Catalogue.jpg" style="width: 100px; height: 100px;" />
          <div style="margin-left: 10px;">
            <h3>PRODUCT</h3>
            <h2>CATALOGUE</h2>
          </div>
        </div>
      </a>

      <div class="rgby-system__block">
        <div class="rgby__list">
          <div class="rgby red" [class.active]="quarterIdx == 0">
            <h3>RED</h3>
            <h2>Q1</h2>
            <p *ngIf="quarterIdx == 0">CURRENT QTR</p>
          </div>
          <div class="rgby green" [class.active]="quarterIdx == 1">
            <h3>GREEN</h3>
            <h2>Q2</h2>
            <p *ngIf="quarterIdx == 1">CURRENT QTR</p>
          </div>
          <div class="rgby blue" [class.active]="quarterIdx == 2">
            <h3>BLUE</h3>
            <h2>Q3</h2>
            <p *ngIf="quarterIdx == 2">CURRENT QTR</p>
          </div>
          <div class="rgby yellow" [class.active]="quarterIdx == 3">
            <h3>YELLOW</h3>
            <h2>Q4</h2>
            <p *ngIf="quarterIdx == 3">CURRENT QTR</p>
          </div>
        </div>

        <div class="rgby-information__block">
          <p>RGBY <span>SYSTEM</span></p>
          <div class="spacer"></div>
          <p>MORE INFORMATION</p>
        </div>
      </div>
    </div>

    <div class="block-3">
      <h1>VIDEO TUTORIALS</h1>

      <div class="tutorial__grid">

        <a class="icon-text__block" href="https://youtu.be/UVAXrsQ_tjs" target="_blank">
          <div class="tutorial__block">
            <img src="/assets/icons/Accessing-your-register.jpg" />
            <p>EXPORTING YOUR <span>MOST RECENT REGISTER</span></p>
          </div>
        </a>

        <a class="icon-text__block" href="https://youtu.be/vZZ9iXaVS0w" target="_blank">
          <div class="tutorial__block">
            <img src="/assets/icons/Sharing-your-register.jpg" />
            <p><span>EMAILING YOUR REGISTER</span> TO A THIRD PARTY</p>
          </div>
        </a>
        <a class="icon-text__block" href="https://youtu.be/RBfi7d0mOqM" target="_blank">
          <div class="tutorial__block">
            <img src="/assets/icons/Requesting-a-replacement-item.jpg" />
            <p><span>REQUESTING A REPLACEMENT</span> OF FAILED ITEMS</p>
          </div>
        </a>
        <a class="icon-text__block" href="https://youtu.be/oRfdQD_mq4Y" target="_blank">
          <div class="tutorial__block">
            <img src="/assets/icons/Recent-Jobs-List.jpg" />
            <p>VIEWING YOUR <span>MOST RECENT JOBS</span></p>
          </div>
        </a>
        <a class="icon-text__block" href="https://youtu.be/wsFqC_EgMk0" target="_blank">
          <div class="tutorial__block">
            <img src="/assets/icons/Equipment-Due-Inspection.jpg" />
            <p>FINDING EQUIPMENT <span>DUE FOR INSPECTION</span> THIS MONTH</p>
          </div>
        </a>
      </div>
    </div>

    <div class="block-3">
      <h1>FREE DOWNLOADS</h1>
      <p>The following PDFs are available for free download</p>

      <div class="downloads__grid">
        <a class="tutorial__block" href="https://www.ranger.com.au/wp-content/uploads/2022/10/Ranger-Services-Brochure.pdf" target="_blank">
          <img src="/assets/icons/Ranger-Services-Brochure.jpg" />
          <p>RANGER <span>SERVICES BROCHURE</span></p>
        </a>

        <a class="tutorial__block" href="https://cdn2.hubspot.net/hubfs/6669860/Ranger%20Resources/Ranger-Inspections-and-Testing-Brochure-2018.pdf" target="_blank">
          <img src="/assets/icons/Testing-and-Inspections-Guide.jpg" />
          <p><span>TESTING AND INSPECTIONS</span> GUIDE</p>
        </a>

        <a class="tutorial__block" href="https://www.ranger.com.au/wp-content/uploads/2022/10/List-of-Australia-Standards.pdf" target="_blank">
          <img src="/assets/icons/List-of-Australian-Standards.jpg" />
          <p>LIST OF <span>AUSTRALIAN STANDARDS</span></p>
        </a>

        <a class="tutorial__block" href="https://www.ranger.com.au/wp-content/uploads/2022/10/How-to-correctly-fit-a-harness_Final.pdf" target="_blank">
          <img src="/assets/icons/How-to-correctly-fit-a-harness.jpg" />
          <p>HOW TO <span>CORRECTLY FIT</span> A HARNESS</p>
        </a>

        <a class="tutorial__block" href="https://www.ranger.com.au/wp-content/uploads/2022/10/How-to-check-your-lifting-chain_Final.pdf" target="_blank">
          <img src="/assets/icons/How-to-check-your-lifting-chain.jpg" />
          <p>HOW TO <span>CHECK YOUR LIFTING CHAIN</span></p>
        </a>


        <a class="tutorial__block" href="https://www.ranger.com.au/wp-content/uploads/2022/10/2022-Pre-Inspection-Checklist.pdf" target="_blank">
          <img src="/assets/icons/Pre-Inspection-Checklist.jpg" />
          <p><span>PRE INSPECTION</span> CHECKLIST</p>
        </a>
      </div>
    </div>

    <div class="block-3">
      <h1>AVAILABLE FOR PURCHASE</h1>
      <p>The following resources are available for purchase. Please contact your RANGER Account Manager on 1300 SLINGS or contact us below.</p>

      <div class="purchase__grid">
        <div class="tutorial__block purchase__block" (click)="rigPurchaseEmail('HOW TO DISPOSE OF DAMAGED LIFTING EQUIPMENT')">
          <img src="/assets/icons/How-to-dispose-of-damaged-lifting-equipment.jpg" />
          <p>HOW TO <span>DISPOSE OF DAMAGED</span> LIFTING EQUIPMENT</p>
        </div>

        <div class="tutorial__block purchase__block" (click)="rigPurchaseEmail('WLL CHARTS')">
          <img src="/assets/icons/WLL-Charts.jpg"/>
          <p><span>WLL</span> CHARTS</p>
        </div>

        <div class="tutorial__block purchase__block" (click)="rigPurchaseEmail('INSPECTION BOARD')">
          <img src="/assets/icons/Inspection-Board.jpg"/>
          <p><span>INSPECTION</span> BOARD</p>
        </div>

        <div class="tutorial__block purchase__block" (click)="rigPurchaseEmail('WHY LIFTING EQUIPMENT BREAKS')">
          <img src="/assets/icons/Why-lifting-equipment-breaks.jpg" />
          <p>WHY LIFTING <span>EQUIPMENT BREAKS</span></p>
        </div>
      </div>
    </div>
  </div>
</div>
